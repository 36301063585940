<template>
    <div>
        <div class="seaItem" v-for="(item, index) in costList" :key="index">
            <div class="itemTitleBox">
              <div>
                <img :src="other_cost" alt="" style="width:30px;height:30px;margin-right: 10px;">
                <span class="itemTitle">{{ item.group_name }}：</span>
                  <span
                    class="itemMOney"
                    v-for="(it, id) in item.group_total_price"
                    :key="id"
                  >
                    <span v-if="item.group_total_price[id]">
                      {{ id }}
                      <span>{{ item.group_total_price[id].toFixed(2) }}； </span>
                    </span>

                  </span
                >
                <span class="itemTips"></span>
              </div>
              <div class="btn">
                <el-button
                  class="booking"
                  type="success"
                  size="small"
                  style="margin-right:24px"
                  @click="addCost(index)"
                  v-if="edit"
                  >添加费用</el-button
                >
                <span @click="changeShow(index)" v-if="!item.open"
                  >展开<i class="el-icon-arrow-right"></i
                ></span>
                <span @click="changeShow(index)" v-else
                  >收起<i class="el-icon-arrow-down"></i
                ></span>
              </div>
            </div>
            <div class="itemContent" v-if="item.open">
              <div class="tableLine tableTitle">
                <div class="table1" style="width:120px"  v-if="edit"></div>
                <div class="table1"></div>
                <div class="table2">币种</div>
                <div class="table3">计量单位</div>
                <div class="table4">单价</div>
                <div class="table6">备注</div>
              </div>
              <div class="tableLine" v-for="(it, id) in item.data_list" :key="id">
                <div class="table1"  v-if="edit" style="width:120px;text-align:center">
                  <img :src="del_image" alt=""  class="cursor" @click="cost_item_del(index,id,it)">
                </div>
                <div class="table1">{{ it.name_zh }}</div>
                <div class="table2">{{ it.code }}</div>
                <div class="table3" >{{ (it.price==0.01)&&it.measure=='SET' ? '':it.measure }}</div>
                <div class="table4">
                  <div v-if="it.measure != '箱' && it.measure != 'GP'">
                    <!-- <span v-if="it.price==0.01.01&&it.measure=='SET'">{{ it.price==0.01.01?0:it.price|my_Fixed  }}</span> -->
                    <span v-if="(it.price==0.01)"></span>
                    <span v-else>{{ it.price|my_Fixed  }} {{it.lowest_price?',':''  }}</span>
                    <span class="MINI" v-if="it.lowest_price">MINI:{{ it.lowest_price }}</span>
                  </div>
                  <div v-else>
                    <div class="costItemContent">
                      {{ Number(it.GP20).toFixed(2) }}
                      <span>/20GP</span>
                    </div>
                    <div class="costItemContent">
                      {{ Number(it.GP40).toFixed(2) }}
                      <span>/40GP</span>
                    </div>
                    <div class="costItemContent">
                      {{ Number(it.HC40).toFixed(2) }}
                      <span>/40HQ</span>
                    </div>
                  </div>
                </div>
                <div class="table6" :title='it.remarks'>{{ it.remarks|textFormat }}</div>
              </div>
              <div v-if="item.data_list.length==0" class="nodata">{{'暂无'+item.group_name}}</div>
            </div>
            <baseInfo v-if="item.open && item.plan_remarks_list.length" :list="item.plan_remarks_list"></baseInfo>
        </div>
    </div>
</template>
<script>
import baseInfo from "~ymp/components/common/baseInfo";
export default {
    components:{
      baseInfo
    },
    props:{
        costList:{
            type:Array,
            default:function () {
                return {}
            }
        },
        edit:{
          type:Boolean,
          default:function () {
            return false
          }
        }
    },
    data(){
      return{
        sea_cost:"https://ym-attached-file.oss-cn-shanghai.aliyuncs.com/miniProgram/ship2.png",
        other_cost:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/ymp/appcCar.png",
        del_image:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/ymp/del_icon.png",
      }
    },
    filters:{
      my_Fixed(value){
        let num=0
        if(value){
          num=Number(value).toFixed(2)
        }
        return num
      }
    },
    methods:{
      // 打开 关闭
      changeShow(index){
        this.$emit('open_close',{key:index});
      },

      // 删除费用
      cost_item_del(index,i,obj){
        this.$emit('cost_item_del',{index,i,obj});
      },
      // 添加费用
      addCost(index){
        this.$emit('addCost',{index});
      },

      // 父组件中加上这个函数
      // open_close(e){ //打开 关闭
      //     this.costList[e.key].open=!this.costList[e.key].open
      //   },

      //
    }
}
</script>
<style scoped  lang="less">
  .MINI{
    padding-left:5px;
  }
  .booking {
    background-color: #91be42;
    border-color: #91be42;
  }
  .seaItem {
    margin-bottom: 30px;
    .itemTitleBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .btn {
        font-size: 16px;
        color: #999;
        cursor: pointer;
      }
    }
    .itemTitleBox {
      // padding-bottom: 20px;
    }
    .el-icon-ship {
      display: inline-block;
      width: 30px;
      height: 30px;
      background: #ff7727;
      border-radius: 50%;
      text-align: center;
      line-height: 30px;
      font-size: 16px;
      color: #fff;
      margin-right: 10px;
    }
    .itemTitle {
      font-weight: 900;
      font-size: 18px;
    }
    .itemMOney {
      font-weight: 900;
      font-size: 18px;
    }
    .itemTips {
      font-size: 16px;
      color: #ff7727;
      padding-left: 10px;
    }
    .tableLine {
      display: flex;
      align-items: center;
      min-height: 60px;
      border-bottom: 1px solid #EBEEF5;
    }
    .tableLine>div{
      display:flex;
      padding: 13px 0;
      align-items: center;
    }
    .tableLine {
      .table1 {
        width: 250px;
      }
      .table2 {
        width: 220px;
      }
      .table3 {
        width: 220px;
      }
      .table4 {
        width: 220px;
      }
      .table5 {
        width: 220px;
      }
      .table6 {
        width: 300px;
        text-align: center;


        padding: 0;
        max-height: 40px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }
    .tableTitle {
      background: #fbfcfa;
      border-bottom: 1px solid #ebeef5;
    }
  }
  .nodata{
    height: 100px;
    text-align: center;
    line-height: 100px;
    color: #606266;
  }
</style>

