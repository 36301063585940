<template>
  <div class="baseInfo">
    <pre class="list overHide" v-if="show">
        {{list[0]}}
    </pre>
    <div class="list" v-else>
        <pre style="margin:10px 0" v-for="(item,index) in list" :key="index">{{item}}</pre>
    </div>
    <div class="showBtn">
        <span v-if="show" @click="show=false">展开  <i class="el-icon-caret-right"></i> </span>
        <span v-else @click="show=true">收起 <i class="el-icon-caret-bottom"></i> </span>
    </div>
  </div>
</template>

<script>
export default {
    props:['list'],
  data() {
    return {
      show:true
    };
  },
};
</script>

<style scoped lang="less">
.baseInfo {
  width: 100%;
  padding: 10px 20px;
  background: #fff3ed;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}
.overHide{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
}
.showBtn{
    cursor: pointer;
    min-width: 50px;
}
.list{
    width: 1100px;
}
</style>
